<template>
  <ModuleBase
    :loading="loadingVenue || loadingContract"
    gray
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm'
      }
    ]"
  >
    <BaseModalSmall
      v-if="showSubscriptionConfirmation"
      @close="showSubscriptionConfirmation = false"
    >
      <BaseText size="l">
        {{ $t('treatwell.landing.no_subscription.message') }}
      </BaseText>
      <template #footer>
        <BaseButton
          color="inverted"
          @click="showSubscriptionConfirmation = false"
          v-test="'btn-tw-subscription-cancel'"
        >
          {{ $t('global.actions.cancel') }}
        </BaseButton>
        <BaseButton
          :routerLink="{ name: 'subscription' }"
          @click="onSubscriptionClick"
          v-test="'btn-tw-subscription-submit'"
        >
          {{ $t('treatwell.landing.no_subscription.button') }}
        </BaseButton>
      </template>
    </BaseModalSmall>
    <KYCUltimatumModal v-if="showKYCModal" @close="showKYCModal = false" />
    <div
      :class="[
        $style.dashboard,
        {
          [$style.completed]:
            (treatwellStatus.contractSigned &&
              treatwellStatus.bankDetailsProvided &&
              feedbackEnabled &&
              providedCompanyInfo &&
              treatwellStatus.reviewsPublished &&
              treatwellStatus.descriptionProvided &&
              treatwellStatus.kycStatus?.kycCompleted) ||
            treatwellStatus.hasVenue
        }
      ]"
    >
      <BaseAlert
        v-if="needsToCompleteKYC && hasActiveVenue"
        :text="$t('admin.treatwell.kyc_warning.text')"
        :primaryAction="$t('admin.treatwell.kyc_warning.action')"
        :secondaryAction="$t('global.learn_more')"
        color="warning"
        icon="alert"
        mb
        @primaryAction="showKYCStartModal = true"
        @secondaryAction="goToKYCHelpArticle"
        v-test="'tw-kyc-warning'"
      />
      <KYCStartModal
        v-if="showKYCStartModal"
        @learnMore="goToKYCHelpArticle"
        @close="showKYCStartModal = false"
      />
      <div>
        <div :class="$style.header">
          <div>
            <BaseLabel v-if="hasActiveVenue" color="success" mr>
              {{ $t('admin.treatwell.status.enabled') }}
            </BaseLabel>
            <BaseLabel v-else color="warning" mr>
              {{ $t('admin.treatwell.status.in_progress') }}
            </BaseLabel>
            <BaseLabel
              v-if="treatwellStatus.freeCommissionRemainingDays"
              :color="treatwellStatus.contractSigned ? 'success' : 'warning'"
              v-test="'tw-trial-status'"
            >
              {{
                treatwellStatus.contractSigned
                  ? $t('admin.treatwell.status.benefits_remaining', {
                      days: treatwellStatus.freeCommissionRemainingDays
                    })
                  : $t('admin.treatwell.status.trial_remaining', {
                      days: treatwellStatus.freeCommissionRemainingDays
                    })
              }}
            </BaseLabel>
          </div>
          <BaseText
            v-if="treatwellStatus.hasVenue &&treatwellStatus.venuePending && treatwellStatus.freeCommissionRemainingDays > 0"
            color="warning"
            iconBefore="delete"
            link
            @click="showCancelModal"
            v-test="'tw-churn-button'"
          >
            {{ $t('treatwell.churn') }}
          </BaseText>
          <BaseText
            v-if="hasActiveVenue && treatwellVenue?.url"
            :href="treatwellVenue.url"
          >
            {{ $t('admin.treatwell.my_page') }}
          </BaseText>
        </div>
        <div
          :class="[
            $style.sectionWrapper,
            { [$style.hasActiveVenue]: treatwellStatus.hasVenue }
          ]"
        >
          <BaseCard mb>
            <div :class="$style.taskCard">
              <div :class="$style.taskHeader">
                <img :class="$style.cardImage" src="/img/treatwell/costs.svg" />
                <div>
                  <BaseHeading :mb="0.5">
                    {{ $t('admin.treatwell.main.getting_paid.title') }}
                  </BaseHeading>
                  <BaseText :mb="0.5">
                    {{ $t('admin.treatwell.main.getting_paid.description') }}
                  </BaseText>
                  <div :class="$style.links">
                    <BaseText
                      :href="$t('treatwell.landing.link_video.url')"
                      iconBefore="play-circle"
                      @click="mixpanel.track('Treatwell dashboard - More Info')"
                    >
                      {{ $t('treatwell.landing.link_video.text') }}
                    </BaseText>
                    <BaseText
                      :href="$t('treatwell.landing.link_conditions.url')"
                      iconBefore="book-open"
                      @click="mixpanel.track('Treatwell dashboard - Costs')"
                    >
                      {{ $t('treatwell.landing.link_conditions.text') }}
                    </BaseText>
                  </div>
                </div>
              </div>
              <div :class="$style.tasks">
                <BaseTask
                  v-if="!isTreatwellUser"
                  :heading="
                    $t(
                      `admin.treatwell.main.getting_paid.${treatwellStatus.contractSigned ? 'your_contract' : 'sign_contract'}`
                    )
                  "
                  text=""
                  :state="
                    treatwellStatus.contractSigned ? 'completed' : 'required'
                  "
                  :buttonIcon="
                    treatwellStatus.contractSigned ? 'download' : 'brush'
                  "
                  @click="onTaskClick('contract')"
                  @buttonClick="onTaskClick('contract')"
                  v-test="'contract-todo-item'"
                  v-intercom="'mp-dashboard-task-contract'"
                />
                <BaseTask
                  :heading="
                    company.address
                      ? $t(
                          'admin.treatwell.main.getting_paid.company_info_provided'
                        )
                      : $t(
                          'admin.treatwell.main.getting_paid.provide_company_info'
                        )
                  "
                  text=""
                  :state="
                    providedCompanyInfo
                      ? 'completed'
                      : treatwellStatus.contractSigned
                        ? 'required'
                        : 'blocked'
                  "
                  :buttonIcon="
                    !treatwellStatus.contractSigned
                      ? undefined
                      : providedCompanyInfo
                        ? 'eye'
                        : 'edit'
                  "
                  @click="router.push({ name: 'treatwell-salon-info' })"
                  @buttonClick="router.push({ name: 'treatwell-salon-info' })"
                  v-test="'company-info-todo-item'"
                  v-intercom="'mp-dashboard-task-company-info'"
                />
                <BaseTask
                  :heading="
                    $t(
                      `admin.treatwell.main.getting_paid.${treatwellStatus.bankDetailsProvided ? 'bank_details_provided' : 'provide_bank_details'}`
                    )
                  "
                  text=""
                  :state="
                    treatwellStatus.bankDetailsProvided
                      ? 'completed'
                      : treatwellStatus.contractSigned && providedCompanyInfo
                        ? 'required'
                        : 'blocked'
                  "
                  :buttonIcon="
                    !treatwellStatus.contractSigned || !providedCompanyInfo
                      ? undefined
                      : treatwellStatus.bankDetailsProvided
                        ? 'eye'
                        : 'edit'
                  "
                  @click="onTaskClick('iban')"
                  @buttonClick="onTaskClick('iban')"
                  v-test="'iban-todo-item'"
                  v-intercom="'mp-dashboard-task-iban'"
                />
                <BaseTask
                  v-if="isTreatwellUser || unleash.isEnabled('TW_KYC_FLOW')"
                  :heading="
                    $t(
                      `admin.treatwell.main.getting_paid.${treatwellStatus.kycStatus?.kycCompleted ? 'kyc_completed' : 'kyc_connect'}`
                    )
                  "
                  :text="
                    treatwellStatus.bankDetailsProvided &&
                    !treatwellStatus.kycStatus?.onboardingUrl
                      ? $t('admin.treatwell.main.getting_paid.kyc_preparing')
                      : treatwellVenue &&
                          !treatwellStatus.kycStatus?.kycCompleted
                        ? $t(
                            'admin.treatwell.main.getting_paid.kyc_explanation'
                          )
                        : ''
                  "
                  :state="
                    treatwellStatus.kycStatus?.kycCompleted
                      ? 'completed'
                      : !treatwellStatus.kycStatus?.onboardingUrl
                        ? 'blocked'
                        : 'attention'
                  "
                  :buttonIcon="
                    !treatwellStatus.kycStatus?.kycCompleted &&
                    treatwellStatus.kycStatus?.onboardingUrl
                      ? 'external_link'
                      : undefined
                  "
                  @click="showKYCStartModal = true"
                  @buttonClick="showKYCStartModal = true"
                  v-test="'tw-kyc-todo-item'"
                  v-intercom="'tw-kyc-todo-item'"
                />
                <BaseTask
                  v-if="isTrial"
                  :heading="$t('subscription.heading')"
                  text=""
                  :state="
                    treatwellStatus.kycStatus?.kycCompleted
                      ? 'required'
                      : 'blocked'
                  "
                  :buttonIcon="
                    treatwellStatus.kycStatus?.kycCompleted
                      ? 'external_link'
                      : undefined
                  "
                  @click="onTaskClick('subscribe')"
                  @buttonClick="onTaskClick('subscribe')"
                  v-test="'subscribe-todo-item'"
                  v-intercom="'mp-dashboard-task-subscribe'"
                />
              </div>
            </div>
          </BaseCard>

          <div>
            <BaseCard mb>
              <div
                :class="[
                  $style.taskCard,
                  { [$style.disabled]: !enableSection }
                ]"
              >
                <div :class="$style.taskHeader">
                  <img
                    :class="$style.cardImage"
                    src="/img/treatwell/experience.svg"
                  />
                  <div>
                    <BaseHeading :mb="0.5">
                      {{ $t('admin.treatwell.main.getting_evaluated.title') }}
                    </BaseHeading>
                    <BaseText>
                      {{
                        $t('admin.treatwell.main.getting_evaluated.description')
                      }}
                    </BaseText>
                    <div :class="$style.progress">
                      <div v-if="$screen !== 's'" :class="$style.innerProgress">
                        <BaseProgress
                          mt
                          mb
                          :percentage="
                            Math.min(
                              100,
                              Math.round((reviewsPublished / 50) * 100)
                            )
                          "
                          v-test="'feedback-count'"
                        />
                      </div>
                      <BaseText :mb="0.5" :mt="0.5" :ml="$screen !== 's'">
                        {{
                          reviewsPublished <= 50
                            ? $t(
                                'admin.treatwell.main.getting_evaluated.reviews_published',
                                { count: reviewsPublished }
                              )
                            : $t(
                                'admin.treatwell.main.getting_evaluated.reviews_completed'
                              )
                        }}
                      </BaseText>
                    </div>
                  </div>
                </div>
                <div :class="$style.tasks">
                  <BaseTask
                    :heading="
                      $t(
                        `admin.treatwell.main.getting_evaluated.${feedbackEnabled ? 'feedback_enabled' : 'enable_feedback'}`
                      )
                    "
                    text=""
                    :state="feedbackEnabled ? 'completed' : undefined"
                    :buttonIcon="enableSection ? 'external_link' : undefined"
                    @click="router.push({ name: 'treatwell-enable-feedback' })"
                    @buttonClick="
                      router.push({ name: 'treatwell-enable-feedback' })
                    "
                    v-test="'feedback-todo-item'"
                    v-intercom="'mp-dashboard-task-feedback'"
                  />
                  <BaseTask
                    :heading="
                      $t('admin.treatwell.main.getting_evaluated.bulk_publish')
                    "
                    :text="
                      !treatwellVenue
                        ? $t(
                            'admin.treatwell.main.disabled_reasons.venue_required'
                          )
                        : ''
                    "
                    :state="
                      treatwellStatus.reviewsPublished
                        ? 'completed'
                        : treatwellVenue
                          ? undefined
                          : 'blocked'
                    "
                    :buttonIcon="
                      enableSection && treatwellVenue
                        ? 'external_link'
                        : undefined
                    "
                    @click="router.push({ name: 'treatwell-publish-batch' })"
                    @buttonClick="
                      router.push({ name: 'treatwell-publish-batch' })
                    "
                    v-test="'bulk-push-todo-item'"
                    v-intercom="'mp-dashboard-task-bulk-publish'"
                  />
                </div>
              </div>
            </BaseCard>

            <BaseCard mb>
              <div
                :class="[
                  $style.taskCard,
                  { [$style.disabled]: !enableSection }
                ]"
              >
                <div :class="$style.taskHeader">
                  <img
                    :class="$style.cardImage"
                    src="/img/treatwell/professional.svg"
                  />
                  <div>
                    <BaseHeading :mb="0.5">
                      {{ $t('admin.treatwell.main.getting_seen.title') }}
                    </BaseHeading>
                    <BaseText>
                      {{ $t('admin.treatwell.main.getting_seen.description') }}
                    </BaseText>
                  </div>
                </div>
                <div :class="$style.tasks">
                  <BaseTask
                    :heading="
                      lastMinuteDiscountEnabled
                        ? $t(
                            'admin.treatwell.main.getting_seen.last_minute_discount_enabled'
                          )
                        : $t(
                            'admin.treatwell.main.getting_seen.set_up_last_minute_discount'
                          )
                    "
                    text=""
                    :state="
                      !enableSection
                        ? 'blocked'
                        : lastMinuteDiscountEnabled
                          ? 'completed'
                          : undefined
                    "
                    :buttonIcon="enableSection ? 'edit' : undefined"
                    @click="onTaskClick('last-minute-discount')"
                    @buttonClick="onTaskClick('last-minute-discount')"
                    v-test="'last-minute-discount-todo-item'"
                    v-intercom="'mp-dashboard-task-last-minute-discount'"
                  />
                  <BaseTask
                    :heading="$t('treatwell.description')"
                    text=""
                    :state="
                      !enableSection
                        ? 'blocked'
                        : treatwellStatus.descriptionProvided || hasActiveVenue
                          ? 'completed'
                          : undefined
                    "
                    :buttonIcon="enableSection ? 'eye' : undefined"
                    @click="
                      router.push({ name: 'treatwell-salon-description' })
                    "
                    @buttonClick="
                      router.push({ name: 'treatwell-salon-description' })
                    "
                    v-test="'description-todo-item'"
                    v-intercom="'mp-dashboard-task-description'"
                  />
                  <BaseTask
                    :heading="
                      $t('admin.treatwell.main.getting_seen.set_roster')
                    "
                    text=""
                    :state="enableSection ? 'completed' : 'blocked'"
                    :buttonIcon="enableSection ? 'edit' : undefined"
                    @click="router.push({ name: 'treatwell-set-roster' })"
                    @buttonClick="router.push({ name: 'treatwell-set-roster' })"
                    v-test="'roster-todo-item'"
                    v-intercom="'mp-dashboard-task-roster'"
                  />
                </div>
              </div>
            </BaseCard>
          </div>
        </div>
      </div>
      <Stats mb mt />
    </div>
    <UltimatumModal />
  </ModuleBase>
</template>

<script lang="ts">
export default {
  name: 'TreatwellDashboard',
};
</script>

<script setup lang="ts">
import KYCUltimatumModal from './modals/KYCUltimatumModal.vue';
import Stats from './Stats.vue';
import ModuleBase from '@/modules/ModuleBase.vue';
import KYCStartModal from './modals/KYCStartModal.vue';
import type { ContractTemplate, TreatwellVenue } from '@/types';
import { useQuery, useLazyQuery, useMutation } from '@vue/apollo-composable';
import { computed, inject, ref } from 'vue';
import {
  GET_TREATWELL_VENUE,
  GET_CONTRACT_TEMPLATE,
  GET_FEEDBACK_COUNT,
  TREATWELL_INTEGRATION_REQUEST_REJECT
} from './graphql';
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { useTreatwellStore } from '@/stores/treatwell';
import { useRoute, useRouter } from 'vue-router';
import UltimatumModal from './modals/UltimatumModal.vue';
import unleash from '@/unleash';
import { modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const { isTrial } = useCompanyStore();

const {
  treatwellStatus,
  needsToCompleteKYC,
  kycUtimatumActive,
  hasActiveVenue
} = storeToRefs(useTreatwellStore());
const { getTreatwellStatus } = useTreatwellStore();
const { company, companySettings } = storeToRefs(useCompanyStore());
const { isTreatwellUser } = useCompanyStore();

const mixpanel = inject<any>('mixpanel');

const router = useRouter();

const contractTemplate = ref<ContractTemplate | null>(null);

const {
  load: loadContract,
  loading: loadingContract,
  onResult
} = useLazyQuery(GET_CONTRACT_TEMPLATE, {
  type: 'treatwell',
  id: treatwellStatus.value.contractId
});

if (!isTreatwellUser) {
  loadContract();
}

const showKYCStartModal = ref(false);

const goToKYCHelpArticle = () => {
  mixpanel.track('kyc_help_article_clicked');
  if (company.value.language === 'nl') {
    window.open(
      'http://help.salonized.com/nl/articles/9863280-stappenplan-voor-de-treatwell-verificatieprocedure'
    );
  } else {
    window.open(
      'http://help.salonized.com/en/articles/9863280-guide-to-the-treatwell-verification-procedure'
    );
  }
};

const route = useRoute();
const showKYCModal = ref(
  route.name !== 'treatwell-kyc-start' && kycUtimatumActive.value
);

onResult(({ data }) => {
  contractTemplate.value = data.contractTemplate;
});

const treatwellVenue = ref<Partial<TreatwellVenue>>({});
const { loading: loadingVenue, onResult: onVenueResult } =
  useQuery(GET_TREATWELL_VENUE);
onVenueResult(({ data }) => {
  treatwellVenue.value = data.treatwellVenue;
});

const { locationId, dataScope } = useLocationsStore();

const { result: feedbackCount } = useQuery(GET_FEEDBACK_COUNT, {
  locationId,
  dataScope
});

const providedCompanyInfo = computed(
  () =>
    !!(
      company.value.address &&
      company.value.postalcode &&
      company.value.city &&
      company.value.phone
    )
);

const feedbackEnabled = computed(
  () => companySettings.value.customers.appointmentFeedback
);
const lastMinuteDiscountEnabled = computed(
  () => companySettings.value.bookings.lastMinuteDiscountEnabled
);
const reviewsPublished = computed(
  () => feedbackCount.value?.feedbackCount?.total || 0
);

const enableSection = computed(
  () =>
    (treatwellStatus.value.contractSigned &&
      providedCompanyInfo.value &&
      treatwellStatus.value.bankDetailsProvided &&
      treatwellStatus.value.kycStatus?.kycCompleted) ||
    treatwellStatus.value.hasVenue
);

const showSubscriptionConfirmation = ref(false);
const onTaskClick = (taskName: string) => {
  switch (taskName) {
    case 'subscribe':
      showSubscriptionConfirmation.value = true;
      break;
    case 'contract':
      {
        if (treatwellStatus.value.contractSigned) {
          if (contractTemplate.value?.downloadUrl) {
            window.open(contractTemplate.value.downloadUrl, '_blank');
          }
        } else {
          mixpanel.track('Treatwell dashboard - task - contract modal opened');
          router.push({ name: 'treatwell-sign-contract' });
        }
      }
      break;
    case 'iban':
      {
        if (treatwellStatus.value.contractSigned) {
          mixpanel.track('Treatwell dashboard - task - iban modal opened');
          router.push({ name: 'treatwell-provide-iban' });
        }
      }
      break;
    case 'last-minute-discount': {
      mixpanel.track(
        'Treatwell dashboard - task - last minute discount modal opened'
      );
      router.push({ name: 'treatwell-salon-last-minute-discount' });
    }
  }
};

const onSubscriptionClick = () => {
  showSubscriptionConfirmation.value = false;
  mixpanel.track('TWLandingPage-LinkYourAccount-TrialSub');
};

const { t } = useI18n();
const { mutate: treatwellIntegrationRequestReject } = useMutation(
  TREATWELL_INTEGRATION_REQUEST_REJECT,
  () => ({
    variables: {
      input: {
        rejectionReason: 'churned from dashboard'
      }
    }
  })
);

const showCancelModal = () => {
  mixpanel.track('TWLandingPage-cancel-modal-shown');
  modal('confirmation', {
    message: t('treatwell.churn_modal.message'),
    type: 'disable',
    warning: t('treatwell.churn_modal.sub_message')
  }).then(() => {
    mixpanel.track('TWLandingPage-cancel-modal-confirmed');
    treatwellIntegrationRequestReject().then(() => {
      mixpanel.track('TWLandingPage-churned');
      getTreatwellStatus();
      router.push({ name: 'treatwell-landing' });
    });
  });
};

watch(treatwellStatus.value, (curVal) => {
  if (!curVal.hasRequest) {
    router.push({ name: 'treatwell-landing' });
  }
});
</script>

<style lang="scss" module>
.base {
  border-top: 1px solid $color-border;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.dashboard {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  &.completed {
    flex-direction: column-reverse;
  }
}
.taskCard {
  max-width: 100%;
  .base:not(.smallScreen) & {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.taskHeader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-right: $spacing;
  margin-bottom: $spacing;
  .base.mediumScreen & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
}
.cardImage {
  width: 160px;
  max-width: 80%;
  padding: 0 $spacing;
  margin-bottom: $spacing;
}
.tasks {
  max-width: 100%;
  flex-shrink: 0;

  .base.smallScreen & {
    width: 100%;
  }

  .base.mediumScreen & {
    width: 50%;
  }

  .base:not(.smallScreen):not(.mediumScreen) & {
    width: 30%;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: $spacing * 0.5;
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing;
}
.progress {
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}
.innerProgress {
  min-width: 60px;
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing;
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  &.hasActiveVenue {
    flex-direction: column-reverse;
  }
}
</style>
